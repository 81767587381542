import { Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Input } from "../../../components/InputFields";
import { SidebarLayout } from "../../../components/Layout";
import SettingsSidebar from "../../../components/SettingsSidebar";
import { addMessage } from "../../../state/reducers/appReducer";
import { updateTotpEnabled } from "../../../state/reducers/settingsReducer";
import { RootState } from "../../../state/store";
import { fetchBaseOptions } from "../../../utils/fetch";
import { passwordValidator } from "../../../utils/validators";
import { OTPSchema } from "../../login/otp";

const EnableTotpSchema = yup.object().shape({
  password: passwordValidator,
});

const EnableTOTPPage = () => {
  const dispatch = useDispatch();
  const [secret, setSecret] = useState("");
  const [imagePng, setImagePng] = useState("");
  const totpEnabled = useSelector((state: RootState) => state.settings.totpEnabled);
  const recoveryCodesGenerated = useSelector(
    (state: RootState) => state.settings.recoveryCodesGenerated,
  );
  return (
    <SidebarLayout seo={{ title: "Authenticator app" }} sidebar={<SettingsSidebar />}>
      <p className="mb-4">
        A time-based one-time password (TOTP) application automatically generates an authentication
        code that changes after a certain period of time. You will be prompted to enter the TOTP
        after you login with your password.
      </p>
      {totpEnabled && !recoveryCodesGenerated && (
        <p className="px-4 py-2 mb-4 text-green-700 bg-green-100 rounded">
          You have successfully enabled two factor authentication with TOTP.{" "}
          <Link to="/settings/security/recovery">Don't forget to set your recovery codes</Link>.
        </p>
      )}
      <div>
        {totpEnabled ? (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={EnableTotpSchema}
            onSubmit={(values, { setSubmitting }) => {
              fetch("/api/2fa/otp/disable", {
                ...fetchBaseOptions,
                body: JSON.stringify({ password: values.password }),
              })
                .then(async res => {
                  const j: IAPIResponse = await res.json();
                  dispatch(addMessage(j));
                  if (res.ok) {
                    dispatch(updateTotpEnabled(false));
                  }
                })
                .catch(err => console.error(err))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="mt-8 space-y-2">
                <Field
                  required={true}
                  type="password"
                  name="password"
                  label="Password"
                  component={Input}
                />
                <button
                  className="w-full btn btn-delete"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Disable
                </button>
              </Form>
            )}
          </Formik>
        ) : secret !== "" ? (
          <div className="flex flex-col items-center">
            <img className="w-48 h-48 mb-4" src={`data:image/png;base64,${imagePng}`} />
            <code>{secret}</code>
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={OTPSchema}
              onSubmit={(values, { setSubmitting }) => {
                fetch("/api/2fa/otp/set", {
                  ...fetchBaseOptions,
                  body: JSON.stringify({ secret: secret, otp: values.otp }),
                })
                  .then(async res => {
                    const j: IAPIResponse = await res.json();
                    dispatch(addMessage(j));
                    if (res.ok) {
                      dispatch(updateTotpEnabled(true));
                      setImagePng("");
                      setSecret("");
                    }
                  })
                  .catch(err => console.error(err))
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form className="space-y-2">
                  <Field
                    required={true}
                    type="text"
                    name="otp"
                    label="OTP code"
                    component={Input}
                  />
                  <button
                    className="w-full btn btn-primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Enable
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={EnableTotpSchema}
            onSubmit={(values, { setSubmitting }) => {
              fetch("/api/2fa/otp/get", {
                ...fetchBaseOptions,
                body: JSON.stringify({ password: values.password }),
              })
                .then(async res => {
                  if (res.ok) {
                    const j: { Secret: string; Image: string } = await res.json();
                    setSecret(j.Secret);
                    setImagePng(j.Image);
                  } else {
                    const j: IAPIResponse = await res.json();
                    dispatch(addMessage(j));
                  }
                })
                .catch(err => console.error(err))
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="space-y-2">
                <Field
                  required={true}
                  type="password"
                  name="password"
                  label="Password"
                  component={Input}
                />
                <button
                  className="w-full btn btn-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Enable
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </SidebarLayout>
  );
};

export default EnableTOTPPage;
