import { Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Input } from "../../components/InputFields";
import { FormLayout } from "../../components/Layout";
import { addMessage } from "../../state/reducers/appReducer";
import { loginAction } from "../../state/reducers/authReducer";
import { RootState } from "../../state/store";
import { fetchBaseOptions } from "../../utils/fetch";

export const OTPSchema = yup.object().shape({
  otp: yup.string().length(6, "Invalid OTP code").required("Required"),
});

const OTPLoginPage = ({ location }: { location: { state: { next: string } } }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <FormLayout seo={{ title: "Login" }}>
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={OTPSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch("/api/2fa/otp/login", {
            ...fetchBaseOptions,
            body: JSON.stringify({ otp: values.otp, token: token }),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              if (res.ok) {
                dispatch(loginAction());
                if (location.state.next) {
                  navigate(location.state.next);
                } else {
                  navigate("/app");
                }
              } else {
                dispatch(addMessage(j));
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field required={true} type="text" name="otp" label="OTP code" component={Input} />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <p className="mt-4">
        Have you lost access to you 2FA?{" "}
        <Link to="/login/recovery">Login with a recovery code</Link>
      </p>
    </FormLayout>
  );
};

export default OTPLoginPage;
